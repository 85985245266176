import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { FooterAlternate1 } from "../components/footer"
import SEO from "../components/seo"

const IndexPage = ({ location }) => (
   <Layout location={location}>
    <SEO title="How it Works" />

    <header className="header header--basic ">
      <div className="header__content">
          <div className="container">
              <div className="row">
                  <div className="header__inner col-md-9 col-lg-7 col-lg-offset-1">
                      <h1 className="header__title">How it works</h1>
                      <h3 className="header__caption">SalesTribe connects sales people into businesses.</h3>
                  </div>
              </div>
          </div>
      </div>
    </header>
    <div className="landing landing--base">
      <div className="landing__item">
          <div className="container">
              <div className="landing__columns row">
                  <div className="col-xs-12 col-sm-6">
                      <div className="landing__image">
                          <img src="/media/svg/landing-552x580_howitworks-sales-professionals.svg" alt="" />
                      </div>
                  </div>
                  <div className="col-xs-12 col-sm-4 col-sm-offset-1">
                      <Link to="/how-it-works/sales-professionals/" className="landing__copy">
                          <h2 className="landing__title">Sales People</h2>
                          <p>B2B sales has never been more difficult than it is today.</p>
                          <button className="btn btn-link">Learn more</button>
                      </Link>
                  </div>
              </div>
          </div>
      </div>
      <div className="landing__item is-odd">
          <div className="container">
              <div className="landing__columns row">
                  <div className="col-xs-12 col-sm-6 col-sm-push-6">
                      <div className="landing__image">
                          <img src="/media/svg/landing-552x580_howitworks-startups.svg" alt="" />
                      </div>
                  </div>
                  <div className="col-xs-12 col-sm-4 col-sm-pull-5">
                      <Link to="/how-it-works/start-businesses/" className="landing__copy">
                          <h2 className="landing__title">Companies</h2>
                          <p>....need help with sales enablement to adapt to the modern buyer.</p>
                          <button className="btn btn-link">Learn more</button>
                      </Link>
                  </div>
              </div>
          </div>
        </div>

    </div>

    <FooterAlternate1 />

  </Layout>
)

export default IndexPage
